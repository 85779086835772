import React from 'react'
import * as Styled from './ComingSoon.style'
import blockbotGif from 'img/blockbots.gif'

const ComingSoon = () => {
  return (
    <Styled.Main>
      <img src={blockbotGif} alt={'Blockbots'} />
      <h2>{'Launching December 2021'}</h2>
    </Styled.Main>
  )
}

export default ComingSoon
