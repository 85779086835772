import arms1Type1 from './images/arms/1/1.png'
import arms2Type1 from './images/arms/1/2.png'
import arms3Type1 from './images/arms/1/3.png'
import arms4Type1 from './images/arms/1/4.png'
import arms5Type1 from './images/arms/1/5.png'
import arms6Type1 from './images/arms/1/6.png'
import arms7Type1 from './images/arms/1/7.png'
import arms8Type1 from './images/arms/1/8.png'
import arms9Type1 from './images/arms/1/9.png'
import arms10Type1 from './images/arms/1/10.png'
import arms11Type1 from './images/arms/1/11.png'
import arms12Type1 from './images/arms/1/12.png'
import arms13Type1 from './images/arms/1/13.png'
import arms14Type1 from './images/arms/1/14.png'
import arms15Type1 from './images/arms/1/15.png'
import arms16Type1 from './images/arms/1/16.png'
import arms17Type1 from './images/arms/1/17.png'
import arms18Type1 from './images/arms/1/18.png'
import arms19Type1 from './images/arms/1/19.png'
import arms1Type2 from './images/arms/2/1.png'
import arms2Type2 from './images/arms/2/2.png'
import arms3Type2 from './images/arms/2/3.png'
import arms4Type2 from './images/arms/2/4.png'
import arms5Type2 from './images/arms/2/5.png'
import arms6Type2 from './images/arms/2/6.png'
import arms7Type2 from './images/arms/2/7.png'
import arms8Type2 from './images/arms/2/8.png'
import arms9Type2 from './images/arms/2/9.png'
import arms10Type2 from './images/arms/2/10.png'
import arms11Type2 from './images/arms/2/11.png'
import arms12Type2 from './images/arms/2/12.png'
import arms13Type2 from './images/arms/2/13.png'
import arms14Type2 from './images/arms/2/14.png'
import arms15Type2 from './images/arms/2/15.png'
import arms16Type2 from './images/arms/2/16.png'
import arms17Type2 from './images/arms/2/17.png'
import arms18Type2 from './images/arms/2/18.png'
import arms19Type2 from './images/arms/2/19.png'

import body1Type1 from './images/body/1/1.png'
import body2Type1 from './images/body/1/2.png'
import body3Type1 from './images/body/1/3.png'
import body4Type1 from './images/body/1/4.png'
import body5Type1 from './images/body/1/5.png'
import body6Type1 from './images/body/1/6.png'
import body7Type1 from './images/body/1/7.png'
import body8Type1 from './images/body/1/8.png'
import body9Type1 from './images/body/1/9.png'
import body10Type1 from './images/body/1/10.png'
import body11Type1 from './images/body/1/11.png'
import body12Type1 from './images/body/1/12.png'
import body13Type1 from './images/body/1/13.png'
import body1Type2 from './images/body/2/1.png'
import body2Type2 from './images/body/2/2.png'
import body3Type2 from './images/body/2/3.png'
import body4Type2 from './images/body/2/4.png'
import body5Type2 from './images/body/2/5.png'
import body6Type2 from './images/body/2/6.png'
import body7Type2 from './images/body/2/7.png'
import body8Type2 from './images/body/2/8.png'
import body9Type2 from './images/body/2/9.png'
import body10Type2 from './images/body/2/10.png'
import body11Type2 from './images/body/2/11.png'
import body12Type2 from './images/body/2/12.png'
import body13Type2 from './images/body/2/13.png'

import grill1Type1 from './images/grill/1/1.png'
import grill2Type1 from './images/grill/1/2.png'
import grill3Type1 from './images/grill/1/3.png'
import grill4Type1 from './images/grill/1/4.png'
import grill5Type1 from './images/grill/1/5.png'
import grill6Type1 from './images/grill/1/6.png'
import grill7Type1 from './images/grill/1/7.png'
import grill8Type1 from './images/grill/1/8.png'
import grill9Type1 from './images/grill/1/9.png'
import grill10Type1 from './images/grill/1/10.png'
import grill11Type1 from './images/grill/1/11.png'
import grill12Type1 from './images/grill/1/12.png'
import grill13Type1 from './images/grill/1/13.png'
import grill14Type1 from './images/grill/1/14.png'
import grill15Type1 from './images/grill/1/15.png'
import grill16Type1 from './images/grill/1/16.png'
import grill17Type1 from './images/grill/1/17.png'
import grill18Type1 from './images/grill/1/18.png'
import grill19Type1 from './images/grill/1/19.png'
import grill20Type1 from './images/grill/1/20.png'
import grill21Type1 from './images/grill/1/21.png'
import grill1Type2 from './images/grill/2/1.png'
import grill2Type2 from './images/grill/2/2.png'
import grill3Type2 from './images/grill/2/3.png'
import grill4Type2 from './images/grill/2/4.png'
import grill5Type2 from './images/grill/2/5.png'
import grill6Type2 from './images/grill/2/6.png'
import grill7Type2 from './images/grill/2/7.png'
import grill8Type2 from './images/grill/2/8.png'
import grill9Type2 from './images/grill/2/9.png'
import grill10Type2 from './images/grill/2/10.png'
import grill11Type2 from './images/grill/2/11.png'
import grill12Type2 from './images/grill/2/12.png'
import grill13Type2 from './images/grill/2/13.png'
import grill14Type2 from './images/grill/2/14.png'
import grill15Type2 from './images/grill/2/15.png'
import grill16Type2 from './images/grill/2/16.png'
import grill17Type2 from './images/grill/2/17.png'
import grill18Type2 from './images/grill/2/18.png'
import grill19Type2 from './images/grill/2/19.png'
import grill20Type2 from './images/grill/2/20.png'
import grill21Type2 from './images/grill/2/21.png'

import head1Type1 from './images/head/1/1.png'
import head2Type1 from './images/head/1/2.png'
import head3Type1 from './images/head/1/3.png'
import head4Type1 from './images/head/1/4.png'
import head5Type1 from './images/head/1/5.png'
import head6Type1 from './images/head/1/6.png'
import head7Type1 from './images/head/1/7.png'
import head8Type1 from './images/head/1/8.png'
import head9Type1 from './images/head/1/9.png'
import head10Type1 from './images/head/1/10.png'
import head11Type1 from './images/head/1/11.png'
import head12Type1 from './images/head/1/12.png'
import head13Type1 from './images/head/1/13.png'
import head1Type2 from './images/head/2/1.png'
import head2Type2 from './images/head/2/2.png'
import head3Type2 from './images/head/2/3.png'
import head4Type2 from './images/head/2/4.png'
import head5Type2 from './images/head/2/5.png'
import head6Type2 from './images/head/2/6.png'
import head7Type2 from './images/head/2/7.png'
import head8Type2 from './images/head/2/8.png'
import head9Type2 from './images/head/2/9.png'
import head10Type2 from './images/head/2/10.png'
import head11Type2 from './images/head/2/11.png'
import head12Type2 from './images/head/2/12.png'
import head13Type2 from './images/head/2/13.png'

import legs1Type1 from './images/legs/1/1.png'
import legs2Type1 from './images/legs/1/2.png'
import legs3Type1 from './images/legs/1/3.png'
import legs4Type1 from './images/legs/1/4.png'
import legs5Type1 from './images/legs/1/5.png'
import legs6Type1 from './images/legs/1/6.png'
import legs7Type1 from './images/legs/1/7.png'
import legs8Type1 from './images/legs/1/8.png'
import legs9Type1 from './images/legs/1/9.png'
import legs10Type1 from './images/legs/1/10.png'
import legs11Type1 from './images/legs/1/11.png'
import legs12Type1 from './images/legs/1/12.png'
import legs13Type1 from './images/legs/1/13.png'
import legs14Type1 from './images/legs/1/14.png'
import legs1Type2 from './images/legs/2/1.png'
import legs2Type2 from './images/legs/2/2.png'
import legs3Type2 from './images/legs/2/3.png'
import legs4Type2 from './images/legs/2/4.png'
import legs5Type2 from './images/legs/2/5.png'
import legs6Type2 from './images/legs/2/6.png'
import legs7Type2 from './images/legs/2/7.png'
import legs8Type2 from './images/legs/2/8.png'
import legs9Type2 from './images/legs/2/9.png'
import legs10Type2 from './images/legs/2/10.png'
import legs11Type2 from './images/legs/2/11.png'
import legs12Type2 from './images/legs/2/12.png'
import legs13Type2 from './images/legs/2/13.png'
import legs14Type2 from './images/legs/2/14.png'

import panel1Type1 from './images/panel/1/1.png'
import panel2Type1 from './images/panel/1/2.png'
import panel3Type1 from './images/panel/1/3.png'
import panel4Type1 from './images/panel/1/4.png'
import panel5Type1 from './images/panel/1/5.png'
import panel6Type1 from './images/panel/1/6.png'
import panel7Type1 from './images/panel/1/7.png'
import panel8Type1 from './images/panel/1/8.png'
import panel9Type1 from './images/panel/1/9.png'
import panel10Type1 from './images/panel/1/10.png'
import panel11Type1 from './images/panel/1/11.png'
import panel12Type1 from './images/panel/1/12.png'
import panel13Type1 from './images/panel/1/13.png'
import panel14Type1 from './images/panel/1/14.png'
import panel1Type2 from './images/panel/2/1.png'
import panel2Type2 from './images/panel/2/2.png'
import panel3Type2 from './images/panel/2/3.png'
import panel4Type2 from './images/panel/2/4.png'
import panel5Type2 from './images/panel/2/5.png'
import panel6Type2 from './images/panel/2/6.png'
import panel7Type2 from './images/panel/2/7.png'
import panel8Type2 from './images/panel/2/8.png'
import panel9Type2 from './images/panel/2/9.png'
import panel10Type2 from './images/panel/2/10.png'
import panel11Type2 from './images/panel/2/11.png'
import panel12Type2 from './images/panel/2/12.png'
import panel13Type2 from './images/panel/2/13.png'
import panel14Type2 from './images/panel/2/14.png'

export const parts = {
  arms: {
    type1: [arms1Type1, arms2Type1, arms3Type1, arms4Type1, arms5Type1, arms6Type1, arms7Type1, arms8Type1, arms9Type1,
      arms10Type1, arms11Type1, arms12Type1, arms13Type1, arms14Type1, arms15Type1, arms16Type1, arms17Type1, arms18Type1, arms19Type1],
    type2: [arms1Type2, arms2Type2, arms3Type2, arms4Type2, arms5Type2, arms6Type2, arms7Type2, arms8Type2, arms9Type2,
      arms10Type2, arms11Type2, arms12Type2, arms13Type2, arms14Type2, arms15Type2, arms16Type2, arms17Type2, arms18Type2, arms19Type2]
  },
  body: {
    type1: [body1Type1, body2Type1, body3Type1, body4Type1, body5Type1, body6Type1, body7Type1, body8Type1, body9Type1,
      body10Type1, body11Type1, body12Type1, body13Type1],
    type2: [body1Type2, body2Type2, body3Type2, body4Type2, body5Type2, body6Type2, body7Type2, body8Type2, body9Type2,
      body10Type2, body11Type2, body12Type2, body13Type2]
  },
  grill: {
    type1: [grill1Type1, grill2Type1, grill3Type1, grill4Type1, grill5Type1, grill6Type1, grill7Type1, grill8Type1, grill9Type1,
      grill10Type1, grill11Type1, grill12Type1, grill13Type1, grill14Type1, grill15Type1, grill16Type1, grill17Type1, grill18Type1, grill19Type1, grill20Type1, grill21Type1],
    type2: [grill1Type2, grill2Type2, grill3Type2, grill4Type2, grill5Type2, grill6Type2, grill7Type2, grill8Type2, grill9Type2,
      grill10Type2, grill11Type2, grill12Type2, grill13Type2, grill14Type2, grill15Type2, grill16Type2, grill17Type2, grill18Type2, grill19Type2, grill20Type2, grill21Type2]
  },
  head: {
    type1: [head1Type1, head2Type1, head3Type1, head4Type1, head5Type1, head6Type1, head7Type1, head8Type1, head9Type1,
      head10Type1, head11Type1, head12Type1, head13Type1],
    type2: [head1Type2, head2Type2, head3Type2, head4Type2, head5Type2, head6Type2, head7Type2, head8Type2, head9Type2,
      head10Type2, head11Type2, head12Type2, head13Type2]
  },
  legs: {
    type1: [legs1Type1, legs2Type1, legs3Type1, legs4Type1, legs5Type1, legs6Type1, legs7Type1, legs8Type1, legs9Type1,
      legs10Type1, legs11Type1, legs12Type1, legs13Type1, legs14Type1],
    type2: [legs1Type2, legs2Type2, legs3Type2, legs4Type2, legs5Type2, legs6Type2, legs7Type2, legs8Type2, legs9Type2,
      legs10Type2, legs11Type2, legs12Type2, legs13Type2, legs14Type2]
  },
  panel: {
    type1: [panel1Type1, panel2Type1, panel3Type1, panel4Type1, panel5Type1, panel6Type1, panel7Type1, panel8Type1, panel9Type1,
      panel10Type1, panel11Type1, panel12Type1, panel13Type1, panel14Type1],
    type2: [panel1Type2, panel2Type2, panel3Type2, panel4Type2, panel5Type2, panel6Type2, panel7Type2, panel8Type2, panel9Type2,
      panel10Type2, panel11Type2, panel12Type2, panel13Type2, panel14Type2]
  }
}
