import styled from 'styled-components'
import bg from './images/bg.png'

export const Blockbot = styled.div`
  position: relative;
  background-image: url(${bg});
  user-select: none;
  > img {
    position: absolute;
    width: 100%;
    height: auto;
    &:last-of-type {
      position: relative;
    }
  }
  > h3 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 0.75vw;
    font-size: 1vw;
  }
  > h4 {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 20px;
  }
  > span {
    display: block;
    position: absolute;
    font-weight: bold;
    font-size: 0.75vw;
    top: 92%;
    left: 18%;
    opacity: 0.1;
  }
  > div {
    &:first-of-type {
      position: absolute;
      width: 100%;
      bottom: 10px;
      display: flex;
      justify-content: flex-end;
      right: 10px;
      img {
        width: 11.5%;
        margin-left: 1%;
      }
    }
    &:last-of-type {
      position: absolute;
      width: 100%;
      bottom: 10px;
      display: flex;
      justify-content: flex-start;
      left: 10px;
      img {
        width: 9.5%;
      }
    }
  }
`
