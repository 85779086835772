import styled from 'styled-components'

export const Footer = styled.footer`
  width: 100%;
  background-color: #EEE;
  text-align: left;
  padding: 8px 0 8px;
  font-size: 36px;
  border-top: dotted 2px #333;
  p {
    font-size: 14px;
    padding-left: 1%;
  }
`
