import React from 'react'
import triangle from 'components/Blockbot/images/triangle.svg'
import recyclable from 'components/Blockbot/images/recyclable.svg'
import odd from 'components/Blockbot/images/odd.svg'
import twin from 'components/Blockbot/images/twin.svg'
import inbred from 'components/Blockbot/images/inbred.svg'
import amorous from 'components/Blockbot/images/amorous.svg'
import agile from 'components/Blockbot/images/agile.svg'
import centurion from 'components/Blockbot/images/centurion.svg'
import battery25 from 'components/Blockbot/images/battery25.svg'
import battery50 from 'components/Blockbot/images/battery50.svg'
import battery75 from 'components/Blockbot/images/battery75.svg'
import battery100 from 'components/Blockbot/images/battery100.svg'

const triangles = [1, 3, 6, 10, 15, 21, 28, 36, 45, 55, 66, 78, 91, 105, 120, 136, 153, 171, 190, 210, 231, 253, 276, 300, 325, 351, 378, 406, 435, 465, 496, 528, 561, 595, 630, 666]
const circles = [2, 3, 5, 7, 11, 13, 17, 37, 79, 113, 197, 199, 337]
const agiles = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987]

let lastPrime = 0

function init(amount) {

  const getPower = power => {
    if (power >= 65) {
      return <img src={battery100} alt={''} />
    } else {
      if (power >= 55) {
        return <img src={battery75} alt={''} />
      } else {
        if (power > 25) {
          return <img src={battery50} alt={''} />
        } else {
          return <img src={battery25} alt={''} />
        }
      }
    }
  }

  const getAwards = (x, isPrime) => {
    const awards = []
    let i = 0
    if (x === 1) {

    }
    if (!isPrime) {
      if (x === 1) {
        awards.push({ name: 'musical', img: <img src={triangle} alt={'musical'} key={i++} /> })
        awards.push({ name: 'very agile', img: <img src={agile} alt={'agile'} key={i++} /> })
      } else {
        x % 2 && awards.push({ name: 'Odd', img: <img src={odd} alt={'perculiar'} key={i++} />})
        agiles.includes(x) && awards.push({ name: 'agile', img: <img src={agile} alt={'agile'} key={i++} /> })
        !(x % 100) && awards.push({ name: 'Centurion', img: <img src={centurion} alt={'centurion'} key={i++} />})
      }
    } else {
      isPrime === 2 && x !== 2 && awards.push({ name: 'Duplicate', img: <img src={twin} alt={'replicant'} key={i++} /> })
      isPrime === 4 && awards.push({ name: 'Yokel', img: <img src={inbred} alt={'inbred'} key={i++} /> })
      isPrime === 6 && awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
      if (isPrime === 8) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Duplicate', img: <img src={twin} alt={'replicant'} key={i++} /> })
      }
      if (isPrime === 10) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Yokel', img: <img src={inbred} alt={'inbred'} key={i++} /> })
      }
      if (isPrime === 12) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
      }
      if (isPrime === 14) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Duplicate', img: <img src={twin} alt={'replicant'} key={i++} /> })
      }
      if (isPrime === 18) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
      }
      if (isPrime === 20) {
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Amorous', img: <img src={amorous} alt={'amourous'} key={i++} /> })
        awards.push({ name: 'Duplicate',  img: <img src={twin} alt={'replicant'} key={i++} /> })
      }
      circles.includes(x) && awards.push({ name: 'Recyclced', img: <img src={recyclable} alt={'recycled'} key={i++} /> })
      triangles.includes(x) && awards.push({ name: 'musical', img: <img src={triangle} alt={'musical'} key={i++} /> })
      agiles.includes(x) && awards.push({ name: 'agile', img: <img src={agile} alt={'agile'} key={i++} /> })
    }
    return awards
  }

  const getIssue = (arms,grill,panel,body,head,legs) => Number(arms.toString()+grill.toString()+panel.toString()+body.toString()+head.toString()+legs.toString())

  const getIsPrime = num => {
    if (num === 1) return false
    for(let i = 2; i < num; i++)
    if(num % i === 0) return false;
    const _return = num - lastPrime
    lastPrime = num
    return _return
  }

  const getMod = (bot, modder, offset) => (bot + offset) % modder

  const makeBot = bot => {
    const id = bot + 1
    const arms = getMod(bot,19,0)
    const grill = getMod(bot,21,0)
    const panel = getMod(bot,14,1)
    const body = getMod(bot,13,0)
    const head = getMod(bot,13,6)
    const legs = getMod(bot,14,6)

    const isPrime = getIsPrime(id)
    const awards = getAwards(id, isPrime)
    const power = arms + grill + panel + body + legs + head
    const battery = getPower(power)
    const issue = getIssue(arms, grill, panel, body, head, legs)

    return ({ id, arms, grill, panel, body, head, legs, awards, power, battery, isPrime, issue })
  }

  const bots = [{ id: 1, arms: 0, body: 0, legs: 6, grill: 0, panel: 1, head: 6, awards: getAwards(1, 0), battery: getPower(13), power: 13, issue: 6016 } ]
  for (let b = 0; b < amount; b++) {
    const bot = makeBot(bots.length)
    bots.push(bot)
  }
  //let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
  return bots

}

export default init
