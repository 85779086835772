import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import SimpleStorageContract from 'contracts/SimpleStorage.json'
import BlockbotsContract from 'contracts/Blockbots'
import Marketplace from 'components/Marketplace'
import ScrollToTop from 'components/ScrollToTop'
import Header from 'components/Header'
import Homepage from 'components/Homepage'
import Bot from 'components/Bot'
import Footer from 'components/Footer'
import ComingSoon from 'components/ComingSoon'
import makeBots from 'App/Bots'
import * as S from './App.style'
import getWeb3 from 'getWeb3'

class App extends Component {
  state = { storageValue: 0, web3: null, accounts: null, contract: null, bots: false }

  componentDidMount = async () => {
    if (process.env.NODE_ENV === 'development') {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3()

        // Use web3 to get the user's accounts.
        const accounts = await web3.eth.getAccounts()

        // Get the contract instance.
        const networkId = await web3.eth.net.getId()
        const deployedNetwork = BlockbotsContract.networks[networkId]
        const instance = new web3.eth.Contract(
          BlockbotsContract.abi,
          deployedNetwork && deployedNetwork.address
        )

        console.log(instance)

        let botsAmount = await instance.methods.totalSupply().call()
        let isOwner = false
        try {
          //const chainId = await web3.eth.getChainId();
          // await instance.methods.mint(accounts[0], 1, 'https://game.example/item-id-8u5h2m.json').call()
          console.log(await instance.methods.balanceOf(accounts[0]).call())
          botsAmount = await instance.methods.totalSupply().call()
          const owner = await instance.methods.owner().call()
          isOwner = owner === accounts[0]
          console.log(isOwner)
          //console.log(await instance.methods.getBotGrill(botsAmount).call())
        } catch (e) {
          console.log(e)
        }
        // Set web3, accounts, and contract to the state, and then proceed with an
        // example of interacting with the contract's methods.
        const bots = botsAmount === "0" ? [] : makeBots(botsAmount - 1)

        //console.log(await instance.methods.getBotPanel(bots.length).call())
        this.setState({ web3, accounts, contract: instance, bots, isOwner })
      } catch (error) {
        //console.error(error)
      }
    }
  }

  getMod = (bot, modder, offset) => (bot + offset - 1) % modder

  mintBot = async () => {
    const { accounts, contract, bots } = this.state
    const { getMod } = this
    const id = bots.length + 1;
    await contract.methods.mint(
      accounts[0],
      id,
      "https://api.blockbots.online/bot/"+id,
      getMod(id,19,0),
      getMod(id,21,0),
      getMod(id,14,1),
      getMod(id,13,0),
      getMod(id,13,6),
      getMod(id,14,6)
    ).send({ from: accounts[0] }).then(res =>  console.log(res)).catch(e => console.log(e))
    const botsAmount = await contract.methods.totalSupply().call()
    this.setState({ bots: makeBots(botsAmount - 1) })
  }

  // runExample = async () => {
  //   const { accounts, contract } = this.state
  //
  //   // Stores a given value, 5 by default.
  //   await contract.methods.set(5).send({ from: accounts[0] })
  //
  //   // Get the value from the contract to prove it worked.
  //   const botsAmount = await contract.methods.totalSupply().call()
  //   //const response = await contract.methods.get().call()
  //
  //   // Update state with the result.
  //   this.setState({ bots: makeBots(10) })
  // }

  render() {
    const { bots, accounts, contract, isOwner } = this.state
    return (
      <S.Div>
        <Router>
          <ScrollToTop />
          <Route path={'/'} render={() => <Header blueBG={!window.location.pathname.includes('/bot')} />} />
          { process.env.NODE_ENV === 'development' &&<Route exact path={'/'} render={() => <Homepage />} />}
          {!!bots && process.env.NODE_ENV === 'development' &&
            <Switch>
              <Route exact path={'/marketplace'} render={() => <Marketplace {...{ bots, isOwner }}  create={async () => await this.mintBot()} />} />
              <Route path={'/bot/:bot'} render={props => <Bot {...{ bots, accounts, contract }} />} />
            </Switch>
          }
          {process.env.NODE_ENV !== 'development' && <ComingSoon />}
          {!!bots && <Footer {...{ accounts  }} />}
          </Router>
      </S.Div>
    )
  }
}

export default App
