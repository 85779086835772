import styled from 'styled-components'
import { Main as _Main } from 'style'
import { Link as _Link } from 'react-router-dom'

export const Main = styled(_Main)`
  background-color: rgb(0,176,216);
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 22.5%;
    margin-top: -2%;
    margin-bottom: 2%;
  }
  > div {
    margin-bottom: 4%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  > section {
    padding: 6% 5%;
    background-color: #FFF;
    width: 100%;
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4%;
      h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 4%;
      }
      p {
        max-width: 700px;
        margin-bottom: 2%;
        line-height: 28px;
      }
    }
  }

`

export const Link = styled(_Link)`
  color: #FFF;
  border: 1px solid #FFF;
  border-radius: 20px;
  padding: 6px 20px;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  font-weight: bold;
  font-family: "bungee",sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-left: 4%;
  &:first-of-type {
    margin-left: 0;
  }
`
