import styled from 'styled-components'

export const Header = styled.header`
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ blueBG }) => blueBG ? 'rgb(0,176,216)' : 'transparent'};
  h1 {
    margin-bottom: 4px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`
