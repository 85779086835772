import React from 'react'
import * as Styled from './Header.style'
import { Link } from 'react-router-dom'

function Header({ blueBG }) {
  return (
    <Styled.Header {...{ blueBG }}>
      <h1><Link to='/'>{'Blockbots'}</Link></h1>
      <h3>{'By'} <Link to={{ pathname: "https://kitty.international" }} target="_blank">{'Kitty.International'}</Link></h3>
    </Styled.Header>
  )
}

export default Header
