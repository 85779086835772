import React from 'react'
import * as Styled from './Footer.style'

const Footer = ({ accounts }) => {
  return (
    <Styled.Footer>
      <p><b>{'Your wallet address: '}</b>{accounts.length > 0 && accounts[0]}</p>
    </Styled.Footer>
  )
}

export default Footer;
