import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  background-color: rgb(0,176,216);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  > img {
    width: 24.5%;
    margin-top: -4%;
    margin-bottom: 2%;
  }
  > h2 {
    color: #FFF;
  }
`;
