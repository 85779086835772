import styled from 'styled-components'
import bg from 'components/Blockbot/images/bg.png'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  > p {
    position: absolute;
    width: auto;
    z-index: 1;
    top: 18%;
    font-weight: bold;
    background-color: #FFF;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    > span {
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-of-type {
      right: 20px; 
    }
    &:last-of-type {
      left: 20px;
    }
  }
`

export const Div = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;
  &:first-of-type {
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;
    background-image: url(${bg});
    > div {
      &:first-of-type, &:last-of-type {
        width: 30px;
        img {
          width: 100%;
        }
      }
      &:first-of-type {
        transform: rotate(180deg);
        margin-left: 5%;
      }
      &:nth-of-type(2) {
        width: 300px;
        h3 {
          display: none;
        }
      }
      &:nth-of-type(3) {
        margin-right: 5%;
      }
    }
  }
  &:last-of-type {
    flex-direction: column;
    align-items: center;
    h2 {
      width: 100%;
      background-color: #EEE;
      text-align: center;
      padding: 8px 0 8px;
      font-size: 36px;
      margin-bottom: 24px;
      border: dotted 2px #333;
    }
    div {
      width: 90%;
      box-sizing: border-box;
      @media (min-width: 1100px) {
        width: 60%;
      }
      background-color: #ECECEC;
      padding: 40px 5%;
      border: dotted 4px #000;
      margin-bottom: 24px;
      h3 {
        text-align: center;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 2px dotted #000;
        span {
          display: block;
          font-size: 32px;
          margin-top: 6px;
        }
      }
      > ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          text-align: center;
          margin-bottom: 18px;
          width: 50%;
          color: #333;
          span {
            display: block;
            font-size: 20px;
            margin-top: 6px;
            color: #000;
          }
        }
      }
    }
  }
`
