import styled from 'styled-components'
import { Main as _Main } from 'style'

export const Main = styled(_Main)`
  h2 {
    padding: 0.4% 0;
    text-align: center;
    font-size: 36px;
    color: #666;
    background-color: #EEE;
    margin-bottom: 1%;
    border-top: dotted 2px #333;
    border-bottom: dotted 2px #333;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5% 1%;
    > a {
      display: block;
      width: 9.5%;
      margin: 0 0.5% 18px 0;
      border: 2px dotted #333;
      box-sizing: border-box;
      &:nth-of-type(10n) {
        margin-right: 0;
      }
    }
  }

`
